
import {Component, Ref, Vue} from 'vue-property-decorator';
import PriceCard from '@/views/motorcycle/common/PriceCard.vue';
import BaseLayout from '@/views/motorcycle/BaseLayout.vue';
import IPriceListData from '@/dataDescriptor/IPriceListData';

@Component<SportsterIron883>({
    components: {BaseLayout, PriceCard},
    metaInfo()
    {
        return {
            title: 'Sportster Iron 883',
            titleTemplate: '%s | Půjčovna Haryků',
            meta: [
                {property: 'og:title', content: 'Sportster Iron 883 | Půjčovna Haryků'},
                {property: 'og:type', content: 'article'},
                {property: 'og:url', content: 'https://www.pujcovna-haryku.cz'},
                {property: 'og:description', content: 'Jsme nadšenci do motocyklů a speciálně do značky Harley Davidson, chceme svoje stroje a radost z jízdy sdílet s ostatními. Proto jsme na jaře 2019 založili malou půjčovnu motocyklů v Pardubicích.'},
                {property: 'og:image', content: 'https://www.pujcovna-haryku.cz' + this.getHeroImages[0]},
                {property: 'twitter:image:src', content: 'https://www.pujcovna-haryku.cz' + this.getHeroImages[0]},
                {property: 'og:image:width', content: '1000'},
                {property: 'og:site_name', content: 'Půjčovna Haryků'},
            ],
        };
    },
})
export default class SportsterIron883 extends Vue
{
    get getMotorcycleAttributes(): object
    {
        return {
            'Řidičské oprávnění': 'A2',
            'Hmotnost': '247 kg',
            'Výkon': '35 kW',
            'Max. otáčky': '5500',
            'Počet míst': '2',
        };
    }

    get getPriceLists(): IPriceListData[]
    {
        return [
            {name: 'Půl den', description: 'Platí i o víkendu', price: 1500},
            {name: 'Den', description: 'Platí i o víkendu', price: 2500},
            {name: 'Víkend', description: 'Převezmu v Pátek.\n' + 'Vrátím v Pondělí.', price: 6500},
        ];
    }

    get getHeroImages(): string[]
    {
        return [
            require('@/assets/images/motorcycle/hero_sportster_iron_883.webp'),
        ];
    }
}
